/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';
import { string, node } from 'prop-types';

function VideoPopup({ modifier, videoSrc, children }) {
  const video = useRef();
  const [show, setShow] = useState(false);

  const handleClick = async () => {
    await video.current.pause();
    setShow(!show);
  };
  useEffect(() => {
    if (show) {
      video.current.play();
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // if (!show) video.current.pause();
  }, [show]);
  useEffect(() => {
    const handleEsc = async e => {
      if (e.keyCode === 27) {
        await video.current.pause();
        setShow(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });
  useEffect(() => {
    const handleEnd = async () => {
      await video.current.pause();
      setShow(false);
    };
    video.current.addEventListener('ended', handleEnd);
    return () => {
      video.current.removeEventListener('ended', handleEnd);
    };
  });
  return (
    <div
      className={
        modifier
          ? `video-popup video-popup--${modifier}`
          : `video-popup`
      }
      onClick={handleClick}
    >
      {children}
      <div
        className={
          show ? 'video-popup__container is-open' : 'video-popup__container'
        }
        onClick={handleClick}
      >
        <video className="video-popup__video" ref={video} preload="metadata">
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

VideoPopup.propTypes = {
  modifier: string,
  videoSrc: string.isRequired,
  children: node.isRequired,
};

export default VideoPopup;
