/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';
import VideoPopup from '../VideoPopup';
import wft from '../../svg/wft.png';
import espn from '../../svg/espn.png';

function XtraToutMobile({ bgColor, textColor, scrollOffset }) {
  const data = useStaticQuery(graphql`
    query HomeXtraToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "xtra-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [
    wftPoster,
    airMaxPoster,
    zionPoster,
    dukeVsUncPoster,
    mlbPoster,
    ownTheGamePoster,
    northFacePoster,
    re2pectPoster,
    cityOfFlightPoster,
  ] = images;
  // const [mlb, ownTheGame, northFace, re2pect, cityOfFlight] = videos;
  const wft_rebrand     = 'https://player.vimeo.com/progressive_redirect/playback/678322438/rendition/1080p/file.mp4?loc=external&signature=8b0dc9d144476f032385f2bc488f42e3140d9c12a3c495a65e956011909d6a27'
  const naruto          = 'https://player.vimeo.com/progressive_redirect/playback/740089542/rendition/1080p/file.mp4?loc=external&signature=a53132a6fbe6e9f7eae22ff939667e0ab2797ebaff4be790d8f40f55efb8a1be'
  const nikeAmd         = 'https://player.vimeo.com/progressive_redirect/playback/695023082/rendition/1080p/file.mp4?loc=external&signature=e34d95600505d6ad50101db52b70c5653fb2242d0747b2e14d6060ee3b210128'
  const dukeVsUnc       = 'https://player.vimeo.com/external/529894752.hd.mp4?s=c943afe86f897a816b26441c36a7d086d1d68625&profile_id=174&oauth2_token_id=1365998561';
  const mlb             = 'https://player.vimeo.com/external/456423981.hd.mp4?s=72c8d1800f9d88f718b09dfeb678cafaaae3faa0&profile_id=175&oauth2_token_id=1365998561';
  const ownTheGame      = 'https://player.vimeo.com/external/433462256.hd.mp4?s=cadfbcf83fc359fb00a318ec952c7110696884b6&profile_id=175&oauth2_token_id=1365998561';
  const northFace       = 'https://player.vimeo.com/external/433462237.sd.mp4?s=97b9e722d8afcb341c8a6e2c2e1478ab8413481d&profile_id=165&oauth2_token_id=1365998561';
  const re2pect         = 'https://player.vimeo.com/external/433462248.sd.mp4?s=de3fda52793efe83a665f37b14a70666637d000d&profile_id=165&oauth2_token_id=1365998561';
  const cityOfFlight    = 'https://player.vimeo.com/external/433462263.hd.mp4?s=807cf50c271e715fff7359a4779098a951d85a79&profile_id=174&oauth2_token_id=1365998561';

  return (
    <SectionTrigger
      className="tout xtra-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption modifier="xtra-tout-title" title="Xtra Projects:" />

      <VideoPopup modifier="xtra-tout" videoSrc={wft_rebrand}>
        <img className="tout-logo--1" src={wft} alt="" />
        <ToutCaption
          modifier="xtra-tout"
          title="REBRAND FILM"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--1" y={[-20, 20]}>
          <ParallaxImage src={wftPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={nikeAmd}>
        <ToutCaption
          modifier="xtra-tout"
          title="AIR MAX DAY"
          logo="nike"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--2" y={[-20, 20]}>
          <ParallaxImage src={airMaxPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={naruto}>
        <ToutCaption
          modifier="xtra-tout"
          title="ZION X NARUTO"
          logo="jordan"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--3" y={[-20, 20]}>
          <ParallaxImage src={zionPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={dukeVsUnc}>
        <img className="tout-logo--2" src={espn} alt="" />
        <ToutCaption
          modifier="xtra-tout"
          title="UNC vs DUKE"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--4" y={[-20, 20]}>
          <ParallaxImage src={dukeVsUncPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={mlb}>
        <ToutCaption
          modifier="xtra-tout"
          title="HISPANIC HERITAGE MONTH"
          logo="mlb"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--5" y={scrollOffset}>
          <ParallaxImage src={mlbPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={ownTheGame}>
        <ToutCaption
          modifier="xtra-tout"
          title="OWN THE GAME"
          logo="jordan"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--6" y={scrollOffset}>
          <ParallaxImage src={ownTheGamePoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={northFace}>
        <ToutCaption
          modifier="xtra-tout"
          title="URBAN EXPLORER"
          logo="tnf"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--7" y={scrollOffset}>
          <ParallaxImage src={northFacePoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={re2pect}>
        <ToutCaption
          modifier="xtra-tout"
          title="RE2PECT"
          logo="jordan"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--8" y={scrollOffset}>
          <ParallaxImage src={re2pectPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>

      <VideoPopup modifier="xtra-tout" videoSrc={cityOfFlight}>
        <ToutCaption
          modifier="xtra-tout"
          title="CITY OF FLIGHT"
          logo="jordan"
        />
        <Parallax className="xtra-tout__img xtra-tout__img--9" y={scrollOffset}>
          <ParallaxImage src={cityOfFlightPoster} alt="xtra" />
        </Parallax>
      </VideoPopup>
    </SectionTrigger>
  );
}

export default React.memo(XtraToutMobile);
